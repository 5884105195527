import React from "react";
import {
    FormButtons, FormFechaEmision, FormMontoInput,
    FormNumComprobanteInput, FormNumSerieInput, FormRUCInput,
    FormTipoComprobante, ResultHomeStatus, ResultObservations,
    ResultReceiptStatus, ResultTaxpayerStatus,
    ResultTitle, TitleForm
} from "../atoms/FormAtoms";
import { ResultTemplateProps, VerifyBillFormProps } from "./interfaces/VerifyBillFormInterfaces";
import { Box } from "@mui/material";
import { usePreloadedQuery } from "react-relay";
import { InvoiceValidationQuery } from "../relay/queries/InvoiceValidationQuery";
import { infoLogMessage } from "../logs/LogConfig";

export const VerifyBillFormTemplate: React.FC<VerifyBillFormProps> = ({
    numRuc,
    codComp,
    numeroSerie,
    numero,
    fechaEmision,
    monto,

    onChange,
    errors,
    resetData,

    control,
    options,

    pickerKey,

    register
}) => {
    return (
        <>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <TitleForm />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <FormRUCInput value={numRuc} onChange={onChange} errors={errors} register={register} />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <FormTipoComprobante control={control} value={codComp} errors={errors} options={options} />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <FormNumSerieInput value={numeroSerie} onChange={onChange} errors={errors} register={register} />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <FormNumComprobanteInput value={numero} onChange={onChange} errors={errors} register={register} />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <FormFechaEmision control={control} pickerKey={pickerKey} value={fechaEmision} errors={errors} />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <FormMontoInput value={monto} onChange={onChange} errors={errors} register={register} />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <FormButtons resetData={resetData} />
            </Box>

        </>
    )
}

export const ResultTemplate: React.FC<ResultTemplateProps> = ({ queryReference }) => {

    const data: any = usePreloadedQuery(InvoiceValidationQuery, queryReference)
    infoLogMessage('data: '+JSON.stringify(data,null,2));

    /*if(data.validateInvoice === null){
        return null
    }*/

    return (
        <>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <ResultTitle />
            </Box>

            {data.validateInvoice && (
                <>
                    {data.validateInvoice?.estadoCp && (
                        <Box sx={{ paddingBottom: '16px' }}>
                            <ResultReceiptStatus value={data.validateInvoice?.estadoCp ?? ''} />
                        </Box>
                    )}

                    {data.validateInvoice?.estadoRuc && (
                        <Box sx={{ paddingBottom: '16px' }}>
                            <ResultTaxpayerStatus value={data.validateInvoice?.estadoRuc ?? ''} />
                        </Box>
                    )}

                    {data.validateInvoice?.condDomiRuc && (
                        <Box sx={{ paddingBottom: '16px' }}>
                            <ResultHomeStatus value={data.validateInvoice?.condDomiRuc ?? ''} />
                        </Box>
                    )}

                    {data.validateInvoice?.observaciones && data.validateInvoice.observaciones.length>0 && (
                        <Box sx={{ paddingBottom: '16px' }}>
                            <ResultObservations value={data.validateInvoice?.observaciones ?? ''} />
                        </Box>
                    )}
                </>
            )}

        </>
    )
}