import { Box, Button, CircularProgress, FormControl, InputLabel, Link, MenuItem, Select, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { DateProps, ErrorResultProps, FooterProps, FormButtonProps, FormDropdownProps, FormGenericProps, ResultProps, ResultTextAreaProps } from "./interfaces/FormAtomsInterfaces";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { es } from "date-fns/locale";

export const FormRUCInput: React.FC<FormGenericProps> = ({
  value,
  onChange,
  errors,
  register
}) => {
  return (
    <TextField
      label="Número de RUC del emisor comprobante"
      variant="outlined"
      required
      value={value}
      fullWidth
      {...register('numRuc')}
      onChange={(e) => {
        onChange(e, 'numRuc');
      }}
      error={errors.numRuc?.type !== undefined}
      helperText={errors.numRuc && errors.numRuc.message}
    />
  );
};

export const FormNumSerieInput: React.FC<FormGenericProps> = ({
  value,
  onChange,
  errors,
  register
}) => {
  return (
    <TextField
      label="Número de serie del comprobante"
      variant="outlined"
      required
      fullWidth
      value={value}
      {...register('numeroSerie')}
      onChange={(e) => {
        onChange(e, 'numeroSerie');
      }}
      error={errors.numeroSerie?.type !== undefined}
      helperText={errors.numeroSerie && errors.numeroSerie.message}
    />
  );
};

export const FormNumComprobanteInput: React.FC<FormGenericProps> = ({
  value,
  onChange,
  errors,
  register
}) => {
  return (
    <TextField
      label="Número del comprobante"
      variant="outlined"
      required
      fullWidth
      value={value}
      {...register('numero')}
      onChange={(e) => {
        onChange(e, 'numero');
      }}
      error={errors.numero?.type !== undefined}
      helperText={errors.numero && errors.numero.message}
    />
  );
};

export const FormMontoInput: React.FC<FormGenericProps> = ({
  value,
  onChange,
  errors,
  register
}) => {
  return (
    <TextField
      label="Monto total"
      variant="outlined"
      fullWidth
      value={value}
      {...register('monto')}
      onChange={(e) => {
        onChange(e, 'monto');
      }}
      error={errors.monto?.type !== undefined}
      helperText={errors.monto && errors.monto.message}
    />
  );
};

export const FormTipoComprobante: React.FC<FormDropdownProps> = ({
  control,
  value,
  errors,
  options
}) => {

  const generateSingleOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl variant="outlined" fullWidth required>
      <InputLabel
        id="tipo-comprobante-label"
        sx={{ color: errors.codComp?.type !== undefined ? 'red' : 'inherit' }}
      >
        Tipo de comprobante
      </InputLabel>
      <Controller
        name="codComp"
        control={control}
        defaultValue={value}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            labelId="tipo-comprobante-label"
            label="Tipo de comprobante"
            error={errors.codComp?.type !== undefined}
            {...field}
          >
            {generateSingleOptions()}
          </Select>
        )}
      />

      {errors.codComp && <Typography variant="caption" sx={{ color: 'red' }}>{errors.codComp.message}</Typography>}
    </FormControl>
  )
}

export const FormFechaEmision: React.FC<DateProps> = ({
  control,
  value,
  pickerKey,
  errors
}) => {

  return (
    <FormControl variant="outlined" fullWidth required>
      <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
        <Controller
          name="fechaEmision"
          control={control}
          defaultValue={value || undefined}
          rules={{ required: true }}
          render={({ field }) => (
            <DatePicker
              label="Fecha de emisión"
              key={Number(pickerKey)}
              slotProps={{
                field:
                  { clearable: true },
                actionBar: {
                  actions: ['clear', 'cancel', 'accept'],
                  lang: 'es'
                }
              }}
              format="dd/MM/yyyy"
              disableFuture
              {...field}
              value={value || undefined}
              onChange={(newValue) => field.onChange(newValue)}
            />
          )}
        />
      </LocalizationProvider>

      {errors.fechaEmision && <Typography variant="caption" sx={{ color: 'red' }}>{errors.fechaEmision.message}</Typography>}
    </FormControl>
  )
}

export const FormButtons: React.FC<FormButtonProps> = ({ resetData }) => {

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" mt={1}>
      <Button type="submit" variant="contained" color="primary" style={{ color: 'white', marginRight: '16px' }} >
        Enviar
      </Button>
      <Button onClick={resetData} variant="outlined">
        Borrar datos
      </Button>
    </Box>
  )
}

export const TitleForm = () => {
  return (
    <Typography variant="h1" sx={{
      fontSize: '2rem',
      fontWeight: 'bold',
      paddingLeft: '8px',
      paddingRight: '5px',
      paddingTop: '10px'
    }}>
      FacturasPE
    </Typography>
  )
}

export const ResultTitle = () => {
  return (
    <Typography variant="h1" sx={{
      fontSize: '2rem',
      fontWeight: 'bold',
      paddingLeft: '8px',
      paddingRight: '5px',
      paddingTop: '10px'
    }}>
      Resultados:
    </Typography>
  )
}

export const ResultOperationState: React.FC<ResultProps> = ({ value }) => {
  return (
    <TextField
      label="Estado de la operación"
      fullWidth
      InputProps={{ readOnly: true }}
      value={value}
    />
  )
}

export const ResultReceiptStatus: React.FC<ResultProps> = ({ value }) => {
  return (
    <TextField
      label="Estado del comprobante"
      fullWidth
      InputProps={{ readOnly: true }}
      value={value}
    />
  )
}

export const ResultTaxpayerStatus: React.FC<ResultProps> = ({ value }) => {
  return (
    <TextField
      label="Estado del contribuyente"
      fullWidth
      InputProps={{ readOnly: true }}
      value={value}
    />
  )
}

export const ResultHomeStatus: React.FC<ResultProps> = ({ value }) => {
  return (
    <TextField
      label="Condición domiciliaria"
      fullWidth
      InputProps={{ readOnly: true }}
      value={value}
    />
  )
}

export const ResultObservations: React.FC<ResultTextAreaProps> = ({ value }) => {
  const observaciones = value.join('\n');

  return (
    <TextField
      label="Observaciones"
      fullWidth
      InputProps={{ readOnly: true }}
      multiline
      rows={3}
      value={observaciones}
    />
  )
}

export const ErrorResult: React.FC<ErrorResultProps> = ({ error }) => {
  return (
    <Box sx={{ paddingTop: '16px', paddingBottom: '16px', textAlign: 'center' }}>
      <Typography variant="h1" sx={{
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingLeft: '8px',
        paddingRight: '5px',
        paddingTop: '10px',
        color: 'red',
        textAlign: 'center',
        display: 'inline-block'
      }}>
        {error}
      </Typography>
    </Box>
  )
}

export const Spinner = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <CircularProgress color="inherit" size={80} thickness={4} style={{ marginBottom: 16 }} />
      <Typography variant="h6">Cargando...</Typography>
    </Box>
  );
};

export const EmbeddedSpinner = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress color="inherit" size={50} thickness={4} style={{ marginBottom: 16 }} />
      <Typography variant="h6">Cargando...</Typography>
    </Box>
  )
}

export const Footer:React.FC<FooterProps> = ({isResultAvailable}) => {

  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box
    component='footer'
    sx={{
      color: 'black',
      textAlign: 'center',
      paddingLeft: 2,
      paddingRight: 2,
      position: isResultAvailable || !isWideScreen ? 'relative' : 'absolute', 
      bottom: 0, 
      width: '90%',
    }}
    >
      <Typography variant="body2">
        © Copyright 2023. Facturas.pe es un servicio de:{' '}
        <Link
          href="https://www.facty.pe"
          color="inherit"
          underline="none"
          style={{ fontWeight: 'bold' }}
        >
          Facty, Inc.
        </Link>
      </Typography>
    </Box>
  );
};