import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
import { green, lightBlue } from '@mui/material/colors';
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import RelayEnvironment from "./RelayEnvironment";
import { RelayEnvironmentProvider } from "react-relay";
import { Spinner } from './atoms/FormAtoms';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      main: lightBlue[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

root.render(
  <React.StrictMode>
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<Spinner />}>
            <GoogleReCaptchaProvider reCaptchaKey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)} >
              <App />
            </GoogleReCaptchaProvider>
          </Suspense>
        </Router>
      </ThemeProvider>
    </RelayEnvironmentProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
