import { useState } from "react";
import { TipoComprobante } from "../entities/TipoComprobante";

export const useTipoComprobanteData = () => {
    const [TIPOS_COMPROBANTE, setTIPOS_COMPROBANTE] = useState<TipoComprobante[]>([
        {value: "01", label: "FACTURA"},
        {value: "03", label: "BOLETA DE VENTA"},
        {value: "04", label: "LIQUIDACIÓN DE COMPRA"},
        {value: "07", label: "NOTA DE CREDITO"},
        {value: "08", label: "NOTA DE DEBITO"},
        {value: "R1", label: "RECIBO POR HONORARIOS"},
        {value: "R7", label: "NOTA DE CREDITO DE RECIBOS"},
    ]);

    const handleSetTipoComprobante = (tipoComprobantes: TipoComprobante[]) => {
        setTIPOS_COMPROBANTE(tipoComprobantes);
    }

    return {TIPOS_COMPROBANTE,handleSetTipoComprobante};
}