import React, { ChangeEvent, Suspense } from "react";
import { TipoComprobante } from "../../util/entities/TipoComprobante";
import { formatDate } from "../../util/regEx/regExConstants";
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from "react-hook-form";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { ResultTemplate, VerifyBillFormTemplate } from "../../templates/VerifyBillFormTemplate";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useQueryLoader } from "react-relay";
import { InvoiceValidationQuery } from "../../relay/queries/InvoiceValidationQuery";
import { ErrorBoundary } from "react-error-boundary";
import { EmbeddedSpinner, ErrorResult, Footer } from "../../atoms/FormAtoms";
import { errorLogMessage, infoLogMessage } from "../../logs/LogConfig";
import { GENERIC_ERROR, INTERNAL_SERVER_ERROR } from "../../util/errors/errorsConst";
import { schema } from "../../util/joi-schema/schema";

interface VerifyBillFormScreenInterface {
    options: TipoComprobante[];
}

/*const mockedData = {
    numRuc: '20100049181',
    numSerie: 'F593',
    numero: '00061049',
    monto: '72.90'
}*/



export function VerifyBillFormScreen({ options }: VerifyBillFormScreenInterface) {
    const [numRuc, setNumRuc] = React.useState('');
    const [codComp, setCodComp] = React.useState('');
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [fechaEmision, setFechaEmision] = React.useState<Date | undefined>();
    const [monto, setMonto] = React.useState('');

    const [errorMessage, setErrorMessage] = React.useState('');

    const [isResultAvailable, setIsResultAvailable] = React.useState(false);
    const [isErrorEnabled, setIsErrorEnabled] = React.useState(false);
    const [pickerKey, setPickerKey] = React.useState("pickerKey");

    const [queryReference, loadQuery] = useQueryLoader(
        InvoiceValidationQuery
    );

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = React.useCallback(async () => {
        if (!executeRecaptcha) {
            infoLogMessage('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('verifyBillForm');
        return token;
    }, [executeRecaptcha]);

    const resetData = React.useCallback(() => {
        setNumRuc('');
        setCodComp('');
        setNumeroSerie('');
        setNumero('');
        setMonto('');
        setPickerKey(Math.random().toString());
        reset({
            codComp: '',
        });

        //setIsResultAvailable(false);
        //setIsErrorEnabled(false);
    }, [])

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
        const { value } = event.target;
        switch (fieldName) {
            case 'numRuc':
                setNumRuc(value);
                break;
            case 'numeroSerie':
                setNumeroSerie(value);
                break;
            case 'numero':
                setNumero(value);
                break;
            case 'monto':
                setMonto(value);
                break;
            default:
                break;
        }
    };


    const { register, handleSubmit, control, formState: { errors }, reset } = useForm({
        resolver: joiResolver(schema)
    });

    const onSubmit = async (data: any) => {

        let recaptchaToken;

        try {
            setIsErrorEnabled(false);
            setIsResultAvailable(false);

            recaptchaToken = await handleReCaptchaVerify();
            
        }
        catch (error) {
            setIsResultAvailable(false);
            setIsErrorEnabled(true);
        }

        loadQuery(
            {
                input: {
                    numRuc: data.numRuc,
                    codComp: data.codComp,
                    numeroSerie: data.numeroSerie,
                    numero: data.numero,
                    fechaEmision: formatDate(data.fechaEmision),
                    monto: String(data.monto),
                    recaptcha: String(recaptchaToken)
                }
            }
        )

        setIsResultAvailable(true);
    };

    const logError = React.useCallback((error: Error, info: React.ErrorInfo) => {
        errorLogMessage(error);
        setErrorMessage(error.message === 'Failed to fetch' ? INTERNAL_SERVER_ERROR : error.message);
    }, []);

    return (
        <Box sx={{
            maxWidth: '500px',
            margin: '0 auto',
            '@media (min-width: 768px)': {
                width: '100%'
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
        }}>
            <Grid container justifyContent="center" alignItems="center">
                <Card elevation={3} sx={{ borderRadius: '20px', width: '90%', marginBottom: '20px' }}>
                    <CardContent sx={{ '&>*:not(:last-child)': { marginBottom: '15px' } }}>
                        <Suspense fallback={<EmbeddedSpinner />}>
                            <Grid item component="form" onSubmit={handleSubmit(onSubmit)}>

                                <VerifyBillFormTemplate numRuc={numRuc} codComp={codComp} numeroSerie={numeroSerie} numero={numero}
                                    fechaEmision={fechaEmision} monto={monto} onChange={handleChange} errors={errors}
                                    resetData={resetData} control={control} options={options} pickerKey={pickerKey} register={register} />


                                {isResultAvailable && (
                                    <ErrorBoundary
                                        onError={logError}
                                        fallback={
                                            <ErrorResult error={errorMessage} />
                                        }>
                                        <ResultTemplate queryReference={queryReference} />
                                    </ErrorBoundary>
                                )}

                                {isErrorEnabled && (
                                    <ErrorResult error={GENERIC_ERROR} />
                                )}

                            </Grid>
                        </Suspense>
                    </CardContent>
                </Card>
                <Footer isResultAvailable={isResultAvailable} />
            </Grid>
        </Box>
    );
}