async function fetchGraphQL(text: any, variables: any) {
    //const REACT_APP_GITHUB_AUTH_TOKEN = process.env.REACT_APP_GITHUB_AUTH_TOKEN;
  
    const headers: any = {
      //Authorization: `bearer ${REACT_APP_GITHUB_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    };
  
    /*const token = localStorage.getItem("token");
  
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }*/
  
    // Fetch data from GitHub's GraphQL API:
    const response = await fetch(
      `${process.env.REACT_APP_GRAPHQL_API_URL}/graphql`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: text,
          variables,
        }),
      }
    );

    const responseData = await response.json();

    const errors = responseData.errors;

    if(errors){
      throw new Error('Error: '+errors[0].message)
    }
    
    return responseData;
  }
  
  export default fetchGraphQL;
  