import { graphql } from "react-relay";

export const InvoiceValidationQuery = graphql`
  query InvoiceValidationQuery($input: InvoiceValidationInput!) {
    validateInvoice(input: $input){
      estadoCp
      estadoRuc
      condDomiRuc
      observaciones
    }
  }
`;
