import React from 'react';
import './App.css';
import { useTipoComprobanteData } from './util/dropdownData/TipoComprobanteData';
import { Box } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { VerifyBillFormScreen } from './screens/MainForm/VerifyBillForm';

function App() {

  const {TIPOS_COMPROBANTE} = useTipoComprobanteData();

  return (
    <Box>
      <Routes>
        <Route path="/" element={<VerifyBillFormScreen options={TIPOS_COMPROBANTE} />} />
      </Routes>
    </Box>
  );
}

export default App;
