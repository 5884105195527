/**
 * @generated SignedSource<<77440f1ff29b0aecadc221ee9cb54793>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InvoiceValidationInput = {
  codComp: string;
  fechaEmision: string;
  monto: string;
  numRuc: string;
  numero: string;
  numeroSerie: string;
  recaptcha?: string | null | undefined;
};
export type InvoiceValidationQuery$variables = {
  input: InvoiceValidationInput;
};
export type InvoiceValidationQuery$data = {
  readonly validateInvoice: {
    readonly condDomiRuc: string | null | undefined;
    readonly estadoCp: string | null | undefined;
    readonly estadoRuc: string | null | undefined;
    readonly observaciones: ReadonlyArray<string | null | undefined> | null | undefined;
  } | null | undefined;
};
export type InvoiceValidationQuery = {
  response: InvoiceValidationQuery$data;
  variables: InvoiceValidationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estadoCp",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estadoRuc",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "condDomiRuc",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "observaciones",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceValidationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceValidation",
        "kind": "LinkedField",
        "name": "validateInvoice",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoiceValidationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceValidation",
        "kind": "LinkedField",
        "name": "validateInvoice",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49add5567acb99bbc3502b4978452ce0",
    "id": null,
    "metadata": {},
    "name": "InvoiceValidationQuery",
    "operationKind": "query",
    "text": "query InvoiceValidationQuery(\n  $input: InvoiceValidationInput!\n) {\n  validateInvoice(input: $input) {\n    estadoCp\n    estadoRuc\n    condDomiRuc\n    observaciones\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "03627fdd1c982e735294483891c79514";

export default node;
