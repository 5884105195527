import Joi from "joi";
import { NUMERIC_STRING_REGEX } from "../regEx/regExConstants";

export const schema = Joi.object({
    numRuc: Joi.string().length(11).regex(NUMERIC_STRING_REGEX).required()
        .messages({
            'string.base': `El número de RUC debe ser una cadena de caracteres.`,
            'string.length': `El número de RUC debe tener una longitud de 11 caracteres.`,
            'string.pattern.base': `El número de RUC debe contener solo números.`,
            'any.required': `El número de RUC es un campo obligatorio.`,
        }),

    codComp: Joi.string().length(2).required()
        .messages({
            'string.base': `El código de comprobante debe ser una cadena de caracteres.`,
            'string.length': `El código de comprobante debe tener una longitud de 2 caracteres.`,
            'any.required': `El código de comprobante es un campo obligatorio.`,
        }),

    numeroSerie: Joi.string().length(4).required()
        .messages({
            'string.base': `El número de serie debe ser una cadena de caracteres.`,
            'string.length': `El número de serie debe tener una longitud de 4 caracteres.`,
            'any.required': `El número de serie es un campo obligatorio.`,
        }),

    numero: Joi.string().max(8).required()
        .messages({
            'string.base': `El número del comprobante debe ser una cadena de caracteres.`,
            'string.max': `El número del comprobante no debe exceder 8 dígitos.`,
            'any.required': `El número del comprobante es un campo obligatorio.`,
        }),

    fechaEmision: Joi.date().iso().required()
        .messages({
            'date.base': `La fecha de emisión debe ser una fecha válida.`,
            'date.format': `La fecha de emisión debe estar en formato ISO.`,
            'any.required': `La fecha de emisión es un campo obligatorio.`,
        }),

    monto: Joi.number().precision(2).required()
        .messages({
            'number.base': `El monto debe ser un número.`,
            'number.precision': `El monto debe tener una precisión de 2 decimales.`,
        }),
});