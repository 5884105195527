export function infoLogMessage(message:string){
    if(process.env.REACT_APP_ENV==='development'){
        const timestamp = new Date().toISOString();
        console.info(`[${timestamp}] [INFO]: ${message}`)
    }
}

export function errorLogMessage(error:Error){
    if(process.env.REACT_APP_ENV==='development'){
        const timestamp = new Date().toISOString();
        console.error(`[${timestamp}] [ERROR]: ${error}`)
    }
}